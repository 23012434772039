<template>
  <!--begin::Advance Table Widget 9-->

  <div class="card card-custom card-stretch gutter-b">

    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">&nbsp;</span>
      </h3>
      <div class="card-toolbar">
          <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click="createEventCategoryClicked"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.EVENT_CATEGORY.NEW')}}</a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 table-responsive">
      <b-table
        id="event-category-table"
        :fields="headers"
        :items="items"
        head-variant="light"
        class="mh-100 table-striped"
        sticky-header
      >

        <template #cell(id)="row">
          <div class='justify-content-end d-flex'>

            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click="selectEventCategoryClicked(row.item.id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mr-3"
              @click="deleteEventCategoryClicked(row.item.id)"
              v-if='row.item.type != "confirm"'
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>

          </div>
        </template>
      </b-table>
    </div>

    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'event-categories-table',
  props: ['items', 'currentCompanyId', 'types'],
  emits: ['createEventCategoryClicked', 'selectEventCategoryClicked', 'deleteEventCategoryClicked'],
  components: {

  },
  watch: {

  },
  mixins: [ toasts ],
  methods: {
    createEventCategoryClicked() {
      this.$emit('createEventCategoryClicked');
    },
    selectEventCategoryClicked(id) {
      this.$emit('selectEventCategoryClicked', id);
    },
    deleteEventCategoryClicked(id) {
      this.$emit('deleteEventCategoryClicked', id);
    }
  },
  data() {
    return {
      headers: [
        {
          key: 'name',
          label: this.$t('COMMON.NAME'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7'
        },

        {
          key: 'id',
          label: '',
          thClass: 'w-110px text-right pr-6 ',
        },
      ],
      list: []
    };
  }
};
</script>
