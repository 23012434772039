<template>
  <tr>
    <td>
      <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ event.name }}</span>
    </td>
    <td>{{ formatDate(event.from_datetime) }}</td>
    <td>{{ event.num_signups }}</td>
    <td>{{ event.num_seats }}</td>
    <td class="text-right pr-0">
      <!---<a href="#"
         class="btn btn-icon btn-light btn-sm mx-3"
         @click="publish_event_clicked(event.event_id)"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">

          <inline-svg src="/assets/svg/Up-2.svg"></inline-svg>

        </span>
      </a>-->
      <a
        :href="event_url"
        target="_blank"
        class="btn btn-icon btn-light btn-sm mx-3"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/Write.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>

      <a
        href="#"
        class="btn btn-icon btn-light btn-sm mx-3"
        @click="select_event_clicked(event.event_id)"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/Write.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
      <a
        href="#"
        class="btn btn-icon btn-light btn-sm mx-3"
        @click="delete_event_clicked(event.event_id)"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'event-table-row',
  props: ['event'],
  emits: ['publish_event_clicked', 'select_event_clicked', 'delete_event_clicked'],
  computed: {
    ...mapGetters(['currentCompanyId']),
    event_url() {
      return '/events/' + this.event.event_id;
    }
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  watch: {

  },
  methods: {
    formatDate(datetime) {
      var mom = dayjs(datetime);

      return mom.format('YYYY-MM-DD HH:mm');
    },
    gotoEvent(event_id){
      this.$router.push(`/event/${event_id}`)
    },
    publish_event_clicked(event_id){
      this.$emit('publish_event_clicked', event_id)
    },
    select_event_clicked(event_id) {
      this.$emit('select_event_clicked', event_id);
    },
    delete_event_clicked(event_id) {
      this.$emit('delete_event_clicked', event_id);
    },
  }
};
</script>
