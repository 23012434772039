<template>
  <div class="no-padding">

    <ErrorPopup
      :error="error_popup_message"
    />

    <MemberPasswordUpdate
      v-if="local_member && local_member.member_id"
      ref="passwordModal"
      :isProfilePage="false"
      :member_id="local_member.member_id"
    />

    <SPARLookup
      ref="spar-lookup-popup"
      :personnr="local_member.in_personnr"
      :member_id="local_member.member_id"
      @save="save_from_spar"
    />


    <h5>{{ $t('MEMBER_EDITOR.PERSONAL_DETAILS') }}</h5>

    <div class="px-1">
      <b-form-group
        v-if="unlocked_field('firstname')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.FIRSTNAME')">

          <b-form-input
            @change="change_data"
            size="sm"
            v-model="local_member.firstname"
            :type="secret_type"
            :state="validate_field(v$.local_member.firstname)"
          />

      </b-form-group>

      <b-form-group
        v-if="unlocked_field('lastname')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.LASTNAME')">

          <b-form-input
            @change="change_data"
            size="sm"
            v-model="local_member.lastname"
            :type="secret_type"
            :state="validate_field(v$.local_member.lastname)"
          />

      </b-form-group>

      <b-form-group
        v-if="unlocked_field('dob')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.DOB')">

          <b-form-input
            @change="change_data"
            size="sm"
            v-model="local_member.dob"
            :type="secret_type"
            :state="validate_field(v$.local_member.dob)"
          />

          <b-form-feedback :state="(
            local_member.dob &&
            !v$.local_member.dob.$invalid &&
            v$.local_member.dob.$errors.length
          ) ? false : true">
          {{ $t('MEMBER.DOB_INVALID_FEEDBACK') }}
          </b-form-feedback>

      </b-form-group>


      <b-form-group
        v-if="unlocked_field('in_personnr')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.PERSONNR')">

          <b-form-input
            @change="change_personnr"
            size="sm"
            v-model="local_member.in_personnr"
            :type="secret_type"
            :state="validate_field(v$.local_member.in_personnr)"
          />

      </b-form-group>


      <b-form-group
        v-if="unlocked_field('in_personnr')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.SWESSN')">

          <b-form-input
            size="sm"
            :disabled="true"
            v-model="is_swessn"
            />

      </b-form-group>

      <b-form-group

        v-if="unlocked_field('in_personnr')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.SPAR_LOOKUP')">
        <b-button :disabled="!valid_personnr" variant="outline-success" size="sm" @click="open_spar_popup">{{$t('MEMBER.DO_SPAR_LOOKUP')}}</b-button>
      </b-form-group>
      <p v-if="show_error_spar" class="error-spar">{{ $t('MEMBER.MUST_CREATE_BEFORE_SPAR') }}</p>

      <b-form-group
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.EMAIL')">

          <b-form-input
            @change="change_email"
            size="sm"
            v-model="local_member.email"
            :type="secret_type"
            :state="validate_field(v$.local_member.email)"
          />
          

      </b-form-group>

      <b-form-group
        v-if="unlocked_field('email')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.EMAIL_STATUS')">

          <b-form-input
            size="sm"
            v-model="translated_email_status"
            type="text"
            :disabled="true"
          />
          

      </b-form-group>

      <b-form-group
        v-if="unlocked_field('phone')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.PHONE')">

          <b-form-input
            @change="change_data"
            size="sm"
            v-model="local_member.phone"
            :type="secret_type"
            :state="validate_field(v$.local_member.phone)"
            />

      </b-form-group>


      <b-form-group
        v-if="unlocked_field('landline')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.LANDLINE')">
        <b-form-input
          @change="change_data"
          :type="secret_type"
          size="sm"
          v-model="local_member.landline"/>
      </b-form-group>



      <b-form-group
        v-if="unlocked_field('public_id')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.PUBLIC_ID')">
        <b-form-input
          @change="change_public_id"
          size="sm"
          v-model="local_member.public_id"
        />
      </b-form-group>

      <b-form-group
        v-if="unlocked_field('avd')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.AVD')">
        <b-form-input
          @change="change_data"
          size="sm"
          v-model="local_member.avd"/>
      </b-form-group>

      <b-form-group
        v-if="unlocked_field('cname')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.CNAME')">
        <b-form-input
          @change="change_data"
          size="sm"
          v-model="local_member.cname"
          :state="validate_field(v$.local_member.cname)"
          />
      </b-form-group>

      <b-form-group
        v-if="unlocked_field('org_number')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.ORG_NUMBER')">
        <b-form-input
          @change="change_data"
          size="sm"
          v-model="local_member.org_number"
          :state="validate_field(v$.local_member.org_number)"
          />
      </b-form-group>

      <b-form-group
        v-if="unlocked_field('student_id')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.STUDENT_ID')">
        <b-form-input
          :disabled="true"
          size="sm"
          @change="change_data"
          v-model="local_member.student_id"/>
      </b-form-group>

      <b-form-group
        v-if="unlocked_field('public_sex')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.PUBLIC_SEX')">
        <b-form-select
          @change="change_data"
          size="sm"
          :options="gender_options"
          v-model="local_member.public_sex"/>
      </b-form-group>


      <b-form-group
        v-if="unlocked_field('sex')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :disabled="true"
        :label="$t('MEMBER.LEGAL_SEX')">
        <b-form-select
          @change="change_data"
          size="sm"
          :options="gender_options"
          v-model="local_member.sex"/>
      </b-form-group>

      <b-form-group
        v-if="unlocked_field('password')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.PASSWORD')">
        <b-button variant="outline-success" size="sm" @click="open_password_modal">{{$t('ACCOUNT.UPDATE_PASSWORD')}}</b-button>
      </b-form-group>

      <b-form-group
        v-if="unlocked_field('lang')"
        label-cols-md="6"
        label-cols-lg="4"
        label-cols-sm="10"
        content-cols-sm="10"
        content-cols-md="6"
        content-cols-lg="4"
        :label="$t('MEMBER.LANG')">
        <b-form-select @change="change_data" size="sm" :options="lang_options" v-model="local_member.lang"/>
      </b-form-group>

      <RightSaveButton
        ref="save-button"
        :text="
          !this.creating
            ? $t('COMMON.SAVE')
            : $t('COMMON.CREATE')
        "
        @clicked="submit_clicked()"
      />

    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import MemberPasswordUpdate from '@/view/components/member_editor_v2/MemberPasswordUpdate.vue';
import { validatePersonnr } from '@/core/services/personnr';
import { validate_email } from '@/core/services/email';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';
import SPARLookup from '@/view/components/SPARLookup.vue';

import { useVuelidate } from '@vuelidate/core'
import { minLength, required, email, requiredIf } from '@vuelidate/validators';
import { helpers } from '@vuelidate/validators'
import ErrorPopup from '@/view/components/ErrorPopup.vue';


const CONSTANT_REQUIREMENTS = [
  'MEMBER_REQ_PERSONNR',
  'MEMBER_REQ_SSN',
  'MEMBER_REQ_AGE',
  'MEMBER_REQ_SEX',
  'MEMBER_REQ_ADDRESS',
  'MEMBER_REQ_FULLNAME',
  'MEMBER_REQ_EMAIL',
  'MEMBER_REQ_PHONE',
  'MEMBER_REQ_EMAILPHONE',
  'MEMBER_REQ_AVD',
  'MEMBER_REQ_ORG_NUMBER',
  'MEMBER_REQ_CNAME',
];


const date_format = 'YYYY-MM-DD'
const must_validate_personnr = (value) => validatePersonnr(value)
const must_validate_dob = (value) => dayjs(value, { date_format }).format(date_format) === value;

export default {

  name: 'PersonalDetailsForm',

  props: ['member','requirements','unlocks'],

  mixins: [ toasts ],

  emits: ['update','validated','change','create'],

  // --- begin vuelidate --- //
  setup () {
    return { v$: useVuelidate() }
  },

  validations () {
    const is_dob_required = this.constreq?.includes('MEMBER_REQ_PERSONNR');
    const is_in_personnr_required = this.constreq?.includes('MEMBER_REQ_SSN');
    const is_email_required = this.constreq?.includes('MEMBER_REQ_EMAIL');
    const is_name_required = this.constreq?.includes('MEMBER_REQ_FULLNAME');
    const is_cname_required = this.constreq?.includes('MEMBER_REQ_CNAME');
    const is_org_number_required = this.constreq?.includes('MEMBER_REQ_ORG_NUMBER');
    const is_phone_required = this.constreq?.includes('MEMBER_REQ_PHONE');
    

    return {
      local_member: {

        phone: {
          required: requiredIf(() => is_phone_required),

          // validation fn
          validation(val) {
            return minLength(1);
          },

          $autoDirty: false
        },


        // firstname field
        firstname: {
          required: requiredIf(() => is_name_required),

          // validation fn
          validation(val) {
            return minLength(1);
          },

          $autoDirty: false
        },

        // lastname field
        lastname: {
          required: requiredIf(() => is_name_required),

          // validation fn
          validation(val) {
            return minLength(1);
          },

          $autoDirty: false

        },

        cname: {
          required: requiredIf(() => is_cname_required),

          // validation fn
          validation(val) {
            return minLength(1);
          },

          $autoDirty: false
        },
        
        org_number: {
          required: requiredIf(() => is_org_number_required),

          // validation fn
          validation(val) {
            return minLength(10);
          },

          $autoDirty: false
        },

        // birhday field
        dob: {

          // required condition
          required: requiredIf(() => is_dob_required),

          // validation fn
          validation(val) {
            if (is_dob_required) return must_validate_dob(val);

            return true;
          },

          $autoDirty: false
        },

        // person number field
        in_personnr: {

          // required condition
          required: requiredIf(() => is_in_personnr_required),

          // validation fn
          validation(val) {
            if (is_in_personnr_required) return must_validate_personnr(val).valid;

            return true;
          },

          $autoDirty: false
        },

        // email field
        email: {

          // required condition
          required: requiredIf(() => is_email_required),

          // validation fn
          email,

          $autoDirty: false
        },
      }
    }
  },

  // -- end vuelidate -- //

  components: {
    MemberPasswordUpdate,
    RightSaveButton,
    SPARLookup,
    ErrorPopup
  },

  watch: {
    member: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_member = { ...this.member };
        this.show_error_spar = false;
      }
    }
  },

  computed: {
    translated_email_status() {
      return this.local_member.email_status ? this.$t('MEMBER.EMAIL_STATUSES.' + this.local_member.email_status.toUpperCase()) + ` (${this.local_member.email_status_at ? dayjs(this.local_member.email_status_at).format('YYYY-MM-DD') : '-'})`: '';
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'companies', 'periods', 'profile', 'settings','isTHS']),
    constreq() {
      return this.requirements.map(item => {
        return CONSTANT_REQUIREMENTS[item.req_id];
      });
    },
    secret_type() {
      return this.local_member.secret ? 'password' : 'text';
    },
    is_swessn() {
      return this.valid_personnr ? this.$t('COMMON.YES') : this.$t('COMMON.NO');
    },
    valid_personnr() {
      const result = this.validate_personnr(this.local_member.in_personnr);

      return result.valid;
    },
    creating() {
      return !(this.local_member && this.local_member.member_id);
    }
  },

  methods: {

    assign_if_set(key, val) {
      if (val && (val+'').length > 0) {
        this.local_member[key] = val;
      }
    },

    save_from_spar(spar_data, personnr, fmt_personnr, sex) {
      this.assign_if_set('firstname', spar_data.fn);
      this.assign_if_set('lastname', spar_data.ln);
      this.assign_if_set('address', spar_data.st);
      this.assign_if_set('zipcode', spar_data.zip);
      this.assign_if_set('post', spar_data.pa);
      this.assign_if_set('co', spar_data.co);

      if (spar_data.date_of_death) {
        this.local_member.deceased = true;
        this.local_member.deactivate_at = spar_data.date_of_death;
        this.local_member.skv_status = 'PEN_DELETED';
      }

      if (spar_data.is_protected) {
        this.local_member.secret = true;
      }

      if (spar_data.is_deactivated) {
        this.local_member.deactivate_at = spar_data.deactivate_at || dayjs().format('YYYY-MM-DD');
        this.local_member.deactivate_reason = spar_data.deactivate_reason;
        this.local_member.deactivated = true;
        this.local_member.skv_status = 'PEN_DELETED';
      }

      if (spar_data.bd) {
        this.assign_if_set('dob', spar_data.bd.y + '-' + spar_data.bd.m + '-' + spar_data.bd.d );
      }

      this.$emit('save_from_spar', this.local_member);
    },

    validate_personnr(personnr) {
      return validatePersonnr(personnr);
    },

    open_spar_popup() {

      this.$refs['spar-lookup-popup'].show();
    },

    unlocked_field(name) {
      const obj = this.unlocks.find(item => item.name === name);

      return obj !== undefined && obj.value;
    },

    open_password_modal() {
      this.$refs['passwordModal'].show();
    },

    async change_email() {
      const email_valid = validate_email(this.local_member.email);

      if (email_valid && this.constreq?.includes('MEMBER_REQ_EMAIL')) {

        const res = await axios.post(`/member/search/email`, { email: this.local_member.email, not_member_id: this.local_member.member_id });

        if (res.status === 200) {
          this.error_popup_message = 'ERR_MEMBER_ALREADY_EXISTS';

          this.$nextTick(()=>{ this.error_popup_message = null; });

          return;
        }

        this.$emit('change', this.local_member);
      }
    },

    async change_public_id() {

      if (this.local_member.public_id) {

        const res = await axios.post(`/member/search/public_id`, { public_id: this.local_member.public_id });

        if (res.status === 200) {
          this.error_popup_message = 'ERR_RENEWS_HAVE_ERRORS';

          this.$nextTick(()=>{ this.error_popup_message = null; });

          return;
        }

        this.$emit('change', this.local_member);
      }

    },

    async change_personnr() {
      this.local_member.swessn = this.valid_personnr;

      if (this.creating && this.valid_personnr && this.constreq?.includes('MEMBER_REQ_SSN')) {

        const res = await axios.post(`/member/search/personnr`, { personnr: this.local_member.in_personnr });

        if (res.status === 200) {
          this.error_popup_message = 'ERR_MEMBER_ALREADY_EXISTS';

          this.$nextTick(()=>{ this.error_popup_message = null; });

          return;
        }

        const result = this.validate_personnr(this.local_member.in_personnr);

        if (result.valid) {
          this.local_member.dob = result.birthdate;
          this.local_member.in_personnr = result.formatted;
        }

        this.$emit('change', this.local_member);
      }
      else {
        const result = this.validate_personnr(this.local_member.in_personnr);

        if (result.valid) {
          this.local_member.dob = result.birthdate;
          this.local_member.in_personnr = result.formatted;
        }

        this.$emit('change', this.local_member);
      }

    },

    /// when the form has changed
    change_data() {
      this.$emit('change', this.local_member);
    },

    async submit_clicked() {
      const valid = await this.v$.$validate();

      if (!valid) {
        // show error toast if it's not validated
        this.toastr(
          'danger',
          this.$t('COMMON.ERROR'),
          this.$t('COMMON.FORM_VALIDATION_FAILED')
        );

        this.$refs['save-button'].stop();

        return;
      }

      if (this.creating) {
        this.$emit('create', this.local_member);
      }
      else {
        this.$emit('update', this.local_member);
      }

      this.$refs['save-button'].stop();
    },

    validate_field(field) {

      // not touched
      if (!field.$dirty) {
        return null;
      }

      // touched and invalid
      if (field.$invalid) return false;

      // touched and valid
      return true;
    }
  },

  mounted() {
    this.show_form = this.expanded;
    this.show_error_spar = false;

    if (this.member) {
      this.local_member = { ...this.member };
    }
  },

  data() {
    return {

      error_popup_message: null,

      show_error_spar: false,

      local_member: {},

      show_form: true,

      gender_options: [
        { text: this.$t('COMMON.MAN'), value: 'M' },
        { text: this.$t('COMMON.WOMAN'), value: 'F' },
        { text: this.$t('COMMON.OTHER'), value: 'O' },
        { text: this.$t('COMMON.UNKNOWN'), value: 'U' },
      ],

      lang_options: [
        { text: this.$t('COMMON.SV'), value: 'sv' },
        { text: this.$t('COMMON.EN'), value: 'en' },
      ],

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

.error-spar {
  font-style: italic;
  color: rgb(144, 47, 47);
}
</style>
