<template>
  <div class="px-5 mt-8" id="page-basic0">
    <b-row>
      <b-col lg="12">
        <b-card title="" class="mb-2 mx-auto mt-8" hide-footer>
          <div>
            <b-form class="mt-8" @submit="saveData">
              <b-form-group
                id="input-group-company_id"
                :label="$t('COMPANY.COMPANY_ID')"
                label-for="input-company_id"
              >
                <b-form-input
                  id="input-company_id"
                  v-model="local_item.company_id"
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-name" :label="$t('COMPANY.NAME')" label-for="input-name">
                <b-form-input
                  id="input-name"
                  v-model="local_item.name"
                  @change="change_data"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-name" :label="$t('COMPANY.SHORTNAME')" label-for="input-name">
                <b-form-input
                  id="input-name"
                  v-model="local_item.shortname"
                  @change="change_data"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-sms_id" label="SMS-ID" label-for="input-sms_id">
                <b-form-input
                  id="input-sms_id"
                  v-model="local_item.sms_id"
                  @change="change_data"
                  type="text"
                  :state="validate_from_id"
                ></b-form-input>
              </b-form-group>

              
              <b-form-group id="input-group-postmark_inbound_email" :label="$t('COMPANY.POSTMARK_INBOUND_EMAIL')" label-for="input-postmark_inbound_email">
                <b-form-input
                  id="input-sms_id"
                  v-model="local_item.postmark_inbound_email"
                  @change="change_data"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-row v-if="local_item.logo !== '' && local_item.logo">
                <b-col lg="12"
                  ><b-img
                    ref="image-logo"
                    style="max-height: 200px;"
                    :src="`${local_item.logo}?rnd=${rnd}`"
                    fluid
                    thumbnail
                  ></b-img
                ></b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col lg="12"
                  ><b-button type="button" variant="success" @click="selectFile()"
                    >{{$t('COMPANY.UPLOAD_LOGO')}}</b-button
                  ></b-col
                >
              </b-row>

              <input
                hidden
                type="file"
                id="file"
                ref="file"
                v-on:change="handleFileUpload()"
                accept="image/*"
              />

              <b-form-group
                class="mt-8 mb-4 cnum-input"
                id="input-group-cnum"
                :label="$t('COMPANY.CNUM')"
                label-for="input-cnum"
              >
                <b-form-input
                  ref="form_cnum"
                  id="input-cnum"
                  v-model="local_item.cnum"
                  @change="change_data"
                  type="text"
                  required
                ></b-form-input>

              </b-form-group>

              <b-form-group id="input-group-type" :label="$t('COMPANY.TYPE')" label-for="input-type">
                <b-form-select
                  id="type"
                  v-model="local_item.type"
                  @change="change_data"
                  :options="companyTypes"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-org_number"
                :label="$t('COMPANY.ORG_NUMBER')"
                label-for="input-org_number"
              >
                <b-form-input
                  id="input-org_number"
                  v-model="local_item.org_number"
                  @change="change_data"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-vat"
                :label="$t('COMPANY.VAT')"
                label-for="input-vat"
              >
                <b-form-input
                  id="input-vat"
                  v-model="local_item.vat"
                  @change="change_data"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-mpid" :label="$t('COMPANY.MPID')" label-for="input-mpid">
                <b-form-input id="input-mpid" v-model="local_item.counter" @change="change_data" type="text"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-pc_id" :label="$t('COMPANY.PC_ID')" label-for="input-pc_id">
                <b-form-input id="input-pc_id" v-model="local_item.pc_id" @change="change_data" type="text"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-street" :label="$t('COMPANY.STREET')" label-for="input-street">
                <b-form-input id="input-street" v-model="local_item.street" @change="change_data" type="text"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-street" :label="$t('COMPANY.CO')" label-for="input-co">
                <b-form-input id="input-co" v-model="local_item.co" @change="change_data" type="text"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-zipcode" :label="$t('COMPANY.ZIPCODE')" label-for="input-zipcode">
                <b-form-input id="input-zipcode" v-model="local_item.zipcode" @change="change_data" type="text"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-post" :label="$t('COMPANY.POST')" label-for="input-post">
                <b-form-input id="input-post" v-model="local_item.post" @change="change_data" type="text"></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-region_code"
                :label="$t('COMPANY.REGION_CODE')"
                label-for="input-region_code"
              >
                <b-form-select
                  id="region_code"
                  v-model="region_code"
                  @change="change_data"
                  :options="regionOptions"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-commune_code"
                :label="$t('COMPANY.COMMUNE_CODE')"
                label-for="input-commune_code"
              >
                <b-form-select
                  id="commune_code"
                  v-model="commune_code"
                  @change="change_data"
                  :options="communeOptions"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-email"
                :label="$t('COMPANY.EMAIL')"
                label-for="input-email"
              >
                <b-form-input id="input-email" v-model="local_item.email" @change="change_data" type="text"></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-phone"
                :label="$t('COMPANY.PHONE')"
                label-for="input-phone"
              >
                <b-form-input id="input-phone" v-model="local_item.phone" @change="change_data" type="text"></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-landline"
                :label="$t('COMPANY.LANDLINE')"
                label-for="input-landline"
              >
                <b-form-input id="input-landline" v-model="local_item.landline" @change="change_data" type="text"></b-form-input>
              </b-form-group>


              <b-form-group id="input-group-gdpr_contact_name" :label="$t('PAGES.FAITH.GDPR_CONTACT_NAME')" label-for="input-gdpr_contact_name">
                <b-form-input
                  id="input-gdpr_contact_name"
                  v-model="local_item.gdpr_contact_name"
                  @change="change_data"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-gdpr_contact_email" :label="$t('PAGES.FAITH.GDPR_CONTACT_EMAIL')" label-for="input-gdpr_contact_email">
                <b-form-input
                  id="input-gdpr_contact_email"
                  v-model="local_item.gdpr_contact_email"
                  @change="change_data"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-c_name"
                :label="$t('COMPANY.C_NAME')"
                label-for="input-c_name"
              >
                <b-form-input id="input-c_name" v-model="local_item.c_name" @change="change_data" type="text"></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-c_email"
                :label="$t('COMPANY.C_EMAIL')"
                label-for="input-c_email"
              >
                <b-form-input id="input-c_email" v-model="local_item.c_email" @change="change_data" type="text"></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-c_phone"
                :label="$t('COMPANY.C_PHONE')"
                label-for="input-c_phone"
              >
                <b-form-input id="input-c_phone" v-model="local_item.c_phone" @change="change_data" type="text"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-web" :label="$t('COMPANY.WEB')" label-for="input-web">
                <b-form-input id="input-web" v-model="local_item.web" @change="change_data" type="text"></b-form-input>
              </b-form-group>


              <b-row class="my-8">
                <b-col>

                <h5 class="mb-4"><strong>{{$t('COMPANY.INVOICE_HEADING')}}</strong></h5>

                <b-form-group id="group-invoice_co" :label="$t('COMPANY.INVOICE_CO')" label-for="field-invoice_co">
                  <b-form-input id="field-invoice_co" v-model="local_item.invoice_co" @change="change_data" type="text"></b-form-input>
                </b-form-group>

                <b-form-group id="group-invoice_address" :label="$t('COMPANY.INVOICE_ADDRESS')" label-for="field-invoice_address">
                  <b-form-input id="field-invoice_address" v-model="local_item.invoice_address" @change="change_data" type="text"></b-form-input>
                </b-form-group>

                <b-form-group id="group-invoice_zipcode" :label="$t('COMPANY.INVOICE_ZIPCODE')" label-for="field-invoice_zipcode">
                  <b-form-input id="field-invoice_zipcode" v-model="local_item.invoice_zipcode" @change="change_data" type="text"></b-form-input>
                </b-form-group>

                <b-form-group id="group-invoice_post" :label="$t('COMPANY.INVOICE_POST')" label-for="field-invoice_post">
                  <b-form-input id="field-invoice_post" v-model="local_item.invoice_post" @change="change_data" type="text"></b-form-input>
                </b-form-group>

                <b-form-group id="group-invoice_country" :label="$t('COMPANY.INVOICE_COUNTRY')" label-for="field-invoice_country">
                  <b-form-select id="field-invoice_country" v-model="local_item.invoice_country" @change="change_data" :options="$t('COUNTRIES')" text-field="value" value-field="key"/>
                </b-form-group>

                <b-form-group id="group-invoice_email" :label="$t('COMPANY.INVOICE_EMAIL')" label-for="field-invoice_email">
                  <b-form-input id="field-invoice_email" v-model="local_item.invoice_email" @change="change_data" type="text"></b-form-input>
                </b-form-group>

                </b-col>
              </b-row>

              <memlist-checkbox 
                :text="$t('COMPANY.RENEWABLE')"
                v-model="local_item.renewable"
                @change="change_data"
              />

              <memlist-checkbox
                v-if="local_item.stats"
                :text="$t('COMPANY.PCINC')"
                v-model="local_item.stats.pcinc"
                @change="change_data"
              />

              <h4 class="mt-8 mb-4">{{ $t('COMPANY_SETTINGS.INCLUDE_ERRORS') }}</h4>
              
              <memlist-checkbox
                v-if="local_settings && local_item.sid === local_item.company_id"
                :text="$t('COMPANY_SETTINGS.NO_ACTIVE_PERIOD')"
                v-model="local_settings.show_warnings.NO_ACTIVE_PERIOD"
              />

              <memlist-checkbox
                v-if="local_settings && local_item.sid === local_item.company_id"
                :text="$t('COMPANY_SETTINGS.NO_ACTIVE_ITEMS_FOR_PERIOD')"
                v-model="local_settings.show_warnings.NO_ACTIVE_ITEMS_FOR_PERIOD"
              />

              <memlist-checkbox
                v-if="local_settings"
                :text="$t('COMPANY_SETTINGS.NO_ACTIVE_ITEMS_FOR_COMPANY')"
                v-model="local_settings.show_warnings.NO_ACTIVE_ITEMS_FOR_COMPANY"
              />

              <memlist-checkbox
                v-if="local_settings && local_item.sid === local_item.company_id"
                :text="$t('COMPANY_SETTINGS.NO_MEMBER_MEMBERSHIPS')"
                v-model="local_settings.show_warnings.NO_MEMBER_MEMBERSHIPS"
              />

              <memlist-checkbox
                v-if="local_settings && local_item.sid === local_item.company_id"
                :text="$t('COMPANY_SETTINGS.MEMBER_INACTIVE_AUTORENEW')"
                v-model="local_settings.show_warnings.MEMBER_INACTIVE_AUTORENEW"
              />

              <memlist-checkbox
                v-if="local_settings && local_item.sid === local_item.company_id"
                :text="$t('COMPANY_SETTINGS.MEMBER_INVALID_EMAIL')"
                v-model="local_settings.show_warnings.MEMBER_INVALID_EMAIL"
              />

              <memlist-checkbox
                v-if="local_settings && local_item.sid === local_item.company_id"
                :text="$t('COMPANY_SETTINGS.MEMBER_INVALID_PERSONNR')"
                v-model="local_settings.show_warnings.MEMBER_INVALID_PERSONNR"
              />

              <memlist-checkbox
                v-if="local_settings && local_item.sid === local_item.company_id"
                :text="$t('COMPANY_SETTINGS.MEMBER_INVALID_DOB')"
                v-model="local_settings.show_warnings.MEMBER_INVALID_DOB"
              />

              <template v-for="(item, i) in extras_data">
                <b-form-group v-bind:key="i" :label="item.name">
                  <b-form-input @change="change_data" v-model="item.value" type="text"></b-form-input>
                </b-form-group>
              </template>

              <p style="color:red;" v-if="form_error">{{ form_error }}</p>

              <RightSaveButton
                ref="save-button"
                :text="$t('COMMON.SAVE')"
                @clicked="saveData()"
              />

            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {
  name: 'basic-company-settings',
  components: {
    RightSaveButton
  },
  mixins: [ toasts ],
  props: ['company_id', 'company', 'extras'],
  emits: ['saved', 'change'],
  computed: {
    creating() {
      if (this.local_item && this.local_item.company_id) {
        return false;
      }

      return true;
    },
    ...mapGetters(['currentPeriodId', 'currentCompanyId', 'companies']),
    validate_from_id() {

      if (this.local_item.sms_id === null || this.local_item.sms_id === undefined) {
        return null;
      }

      // make sure only alphabetical letters are used
      // check this.form.sms_id for any non-alphabetical letters
      const test = /^[A-Za-z\s]*$/.test(this.local_item.sms_id);

      return test && this.local_item.sms_id.length < 12;
    },
    max_cnum() {

      if (!this.companies) {
        return 0;
      }


      const companies = this.companies.filter(item => item.cnum !== null && !isNaN(parseInt(item.cnum+'')));

      companies.sort((a,b) => {
        if (parseInt(a.cnum+'') < parseInt(b.cnum+'')) {
          return -1;
        }

        return 1;
      });

      return companies.length === 0 ? 0 : parseInt(companies[companies.length -1].cnum+'');
    }
  },
  mounted() {

    this.load_regions();

  },
  data() {
    return {
      local_settings: {
        show_warnings: {}
      },
      local_item: {},

      form_error: null,
      regions: {},
      extras_data: [],
      rnd: 'aaa',

      communeOptions: [],
      regionOptions: [],

      companyTypes: [
        { value: 'default', text: this.$t('COMPANY.DEFAULT') },
        { value: 'riks', text: this.$t('COMPANY.RIKS') },
        { value: 'student', text: this.$t('COMPANY.STUDENT') },
        { value: 'workgroup', text: this.$t('COMPANY.WORKGROUP') }
      ],
      region_code: '',
      commune_code: '',

    };
  },
  watch: {
    companies: {
      deep: true,
      handler(val) {

        if (this.local_item.cnum === 1) {
          this.local_item.cnum = this.max_cnum + 1;
        }
      }
    },
    company_id(newValue, oldValue) {
      
    },
    extras(newValue, oldValue) {
      this.extras_data = newValue;
    },


    /// watch company deep
    company: {
      deep: true,
      handler(val) {

        if (this.company) {
          this.local_item = { ...this.company }
        }

        if (!this.local_item.cnum) {
          this.local_item.cnum = this.max_cnum + 1;
        }

        if (this.company.company_settings) {
          this.local_item.gdpr_contact_email = this.company.company_settings.gdpr_contact_email;
          this.local_item.gdpr_contact_name = this.company.company_settings.gdpr_contact_name;

          this.local_settings = this.company.company_settings;
        }

        if (!this.local_settings.show_warnings) {
          this.local_settings.show_warnings = {};
        }
      },
      immediate: true
    },

    region_code(newValue, oldValue) {
      if (newValue) {
        if (
          this.regions[newValue] != undefined &&
          this.regions[newValue].hasOwnProperty('communes')
        )
          this.setCommunes(this.regions[newValue].communes);
      }
    }
  },
  methods: {

    change_data() {
      this.$emit('change', this.local_item);
    },


    setCommunes(communes) {
      this.communeOptions = [];

      for (var k in communes) {
        this.communeOptions.push({ value: k, text: communes[k] });
      }

      this.communeOptions.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },


    async load_regions() {

      try {
        const res = await axios.get('https://zip.memlist.se/api/v1/regions_communes');

        this.regions = res.data.regions;
        this.regionOptions = [];

        for (var k in this.regions) {
          this.regionOptions.push({ value: k, text: this.regions[k].region });
        }

        this.regionOptions.sort(function(a, b) {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SEARCH.UNABLE_LIST_REGIONS'));
      }
    },


    async saveData() {
      const loader = this.$loading.show();

      this.local_item.region_code = this.region_code;
      this.local_item.commune_code = this.commune_code;

      this.local_item.extras = this.extras_data;

      delete this.local_item.requirements;


      this.form_error = false;

      if (!this.creating) {

        try {
          const res = await axios.put(`/company/${this.local_item.company_id}`, { ...this.local_item, show_warnings: this.local_settings.show_warnings } );

          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));

            this.$emit('saved', this.form);

            if (res.data.update_hierarchy) {
              window.location.reload();
            }

          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
          }

          loader && loader.hide();
          this.$refs['save-button'].stop();
        }
        catch (err) {
          if (err.response) {
            console.log(err.response.status);

            if (err.response.status === 409) {
              this.toastr(
                'danger',
                this.$t('COMMON.ERROR'),
                this.$t('COMPANY.CNUM_ALREADY_EXISTS', { cnum: this.local_item.cnum})
              );

              this.form_error = this.$t('COMPANY.CNUM_ALREADY_EXISTS', { cnum: this.local_item.cnum});

              loader && loader.hide();
              this.$refs['save-button'].stop();

              return;
            }
          }

          console.error('catched error', err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        }


      } else {

        try {
          const res = await axios.post('/company', this.local_item)

          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('COMPANY.CREATED'));

            // reload window
            window.location.reload();

          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY.UNABLE_CREATE'));
          }

          loader && loader.hide();
          this.$refs['save-button'].stop();
        }
        catch (err) {

          if (err.response) {
            console.log(err.response.status);

            if (err.response.status === 409) {
              this.toastr(
                'danger',
                this.$t('COMMON.ERROR'),
                this.$t('COMPANY.CNUM_ALREADY_EXISTS', { cnum: this.local_item.cnum})
              );

              this.form_error = this.$t('COMPANY.CNUM_ALREADY_EXISTS', { cnum: this.local_item.cnum});

              loader && loader.hide();
              this.$refs['save-button'].stop();

              return;
            }
          }

          console.error('catched error', err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY.UNABLE_CREATE'));
        }

        this.$refs['save-button'].stop();
      }
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();
    },
    selectFile() {
      this.$refs.file.click();
    },
    uploadFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_source', 'company_logo');
      formData.append('company_id', this.currentCompanyId);

      axios
        .post(`/fileupload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.FILES.UPLOADED'));

          setTimeout(()=>{
            const image = this.$refs['image-logo'];

            const fileobj = res.data.fileobjs[0];

            // Wait for the image to load before accessing its dimensions
            image.onload = () => {
              console.log('dimenions loaded', image.naturalWidth, image.naturalHeight);

              axios.put(`/file/${fileobj.file_id}`, { meta: { width: image.naturalWidth, height: image.naturalHeight } });
            };

          }, 1000);

          this.local_item.logo = res.data.files[0];

          this.rnd = this.randomstr(5);

          //this.wizard.goNext();
        })
        .catch(err => {
          console.error('uploadFile error', err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_UPLOAD'));
        });
    },
    randomstr(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },

  }
};
</script>
